import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import TypingText from '@glass/shared/components/Animate/TypingText';
import { CONTACT_SECTION } from '@glass/shared/modules/resumes/sections';
import Section, { OFFSET_COLOR } from '@glass/shared/components/Section/Section';
import { DEFAULT_SPACING } from '@glass/shared/helpers/constants/spacing';
import DefaultLayoutPage from '@glass/shared/components/Layout/DefaultLayoutPage';
import { yellow } from '@material-ui/core/colors';
import BuildResumeCta from '@glass/shared/components/ResumeContent/BuildResumeCta';
import JsonLD from '@glass/shared/components/Head/JsonLD';
import websiteSchema from '@glass/shared/modules/jsonLd/website.schema';
import organizationSchema from '@glass/shared/modules/jsonLd/organization.schema';
import { CREATE_YOUR_OWN_TEMPLATE } from '@glass/shared/modules/resumes/templates/templateKeys';
import HeroSectionWithCarousel from '@glass/shared/components/Hero/HeroSectionWithCarousel';
import { SIX_HOUR_SEED } from '@glass/shared/helpers/seeds';
import ButtonSecondaryCta from '@glass/shared/components/Button/ButtonSecondaryCta';
import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink';
import HowWeGetYouHiredSection from '@glass/rock/components/Section/HowWeGetYouHiredSection';
import incrementString from '@glass/shared/helpers/incrementString';
import { homeClientQuery } from '@glass/shared/helpers/randomJobTitleQueries';
import { CONTACT_DEFAULT_VARIATION } from '@glass/shared/modules/assignments/feature/configs/carouselDestination';
import CarouselSection from '@glass/shared/components/Carousel/CarouselSection';
import RandomResumeSection from '@glass/shared/components/ResumeContent/RandomResumeSection';
import EditableResumeSection from '@glass/shared/components/ResumeContent/EditableResumeSection';
import JobTitlesSection from '@glass/shared/components/ResumeContent/JobTitlesSection';
import IndustriesSection from '@glass/shared/components/ResumeContent/IndustriesSection';

const useStyles = makeStyles((theme) => ({
  highlight: {
    color: yellow[300],
    fontWeight: theme.typography.fontWeightMedium,
  },
  rotatingWord: {
    color: yellow[300],
  },
}));

// rock-7cfdf63k6.vercel.app
const fallbackId = incrementString(SIX_HOUR_SEED);

const rotatingTexts = [
  'exceptional',
  'smart',
  'greater',
  'in minutes',
  'professional',
  'quick',
  'easy',
  'persuasive',
  'superior',
  'useful',
  'fast',
  'clear',
  'concise',
  'amazing',
  'free',
  'distinct',
  'outstanding',
  'meaningful',
  'perfect',
  'attractive',
  'effortless',
  'accessible',
  'fun',
  'memorable',
  'attractive',
];

const Home = () => {
  const classes = useStyles();
  const buildResumeParams = useMemo(
    () => ({ section: CONTACT_SECTION, template: CREATE_YOUR_OWN_TEMPLATE }),
    [],
  );
  const headerProps = useMemo(() => ({ transparent: true, position: 'fixed', search: true }), []);
  const footerProps = useMemo(() => ({ bgcolor: OFFSET_COLOR }), []);

  return (
    <DefaultLayoutPage
      headerProps={headerProps}
      footerProps={footerProps}
      pageTitle="Best Resume Builder Online, Try For Free"
      description="Rocket Resume is here to help you get your career started. Test out our fast, easy-to-use online resume builder today and let's get you hired!"
    >
      <JsonLD schema={websiteSchema} />
      <JsonLD schema={organizationSchema} />
      <HeroSectionWithCarousel
        includeSubheader
        bgSeed="h"
        titleVariant="h1"
        subtitleVariant="h2"
        fallbackId={fallbackId}
        contentResumeSlug="default"
        destinationOverride={CONTACT_DEFAULT_VARIATION}
        subtitle={
          <>
            Your&nbsp;<span className={classes.highlight}>resume</span>, made<> </>
            <TypingText className={classes.rotatingWord} items={rotatingTexts} />
          </>
        }
      />
      <HowWeGetYouHiredSection />
      <CarouselSection
        destinationOverride={CONTACT_DEFAULT_VARIATION}
        bgcolor={OFFSET_COLOR}
        title="Resume templates recruiters love"
        spacing={DEFAULT_SPACING}
        fallbackId={fallbackId}
      />
      <RandomResumeSection fallbackId={SIX_HOUR_SEED} title="Resumes you'll love for every job" />
      <EditableResumeSection
        fallbackId={SIX_HOUR_SEED}
        bgcolor={OFFSET_COLOR}
        statusTitle="My resume"
      />
      <Section
        title="Create a template just for you"
        subtitle="Use our advanced editor to create or customize your own template so it's just right for you"
      >
        <BuildResumeCta params={buildResumeParams}>Create your own</BuildResumeCta>
      </Section>
      <JobTitlesSection
        bgcolor={OFFSET_COLOR}
        destinationOverride={CONTACT_DEFAULT_VARIATION}
        queryOptions={homeClientQuery}
        bottomChildren={
          <ButtonSecondaryCta component={ResumeBuilderLink}>Build Resume Now</ButtonSecondaryCta>
        }
      />
      <IndustriesSection destinationOverride={CONTACT_DEFAULT_VARIATION} />
    </DefaultLayoutPage>
  );
};

export default Home;
