import HomePage from '../components/Home/HomePage';

export default HomePage;

export async function getStaticProps() {
  return {
    revalidate: 600,
    props: {},
  };
}
